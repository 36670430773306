import React, { useEffect } from "react";
import $ from 'jquery';
import imgUtil from "../../utils/imgUtil";

function ImageView({ imagePath }) {

    const { imgUrl } = imgUtil();

    useEffect(() => {

        $('#js_popup_bg').click(function () {
            $('.js_popup').removeClass("on").css('visibility', 'hidden');
            $(this).hide();
        });

        $(".main_pop .close").click(function () {
            $(this).closest(".main_pop").hide();
            return false;
        });

    }, []);


    // 감추기
    const viewHide = (popName) => {
        $("#display_view" + popName).removeClass("on").css('visibility', 'hidden');
        $("#js_popup_bg").hide();
        return false;
    }

    return(
        <>
            <div id="display_view1" className="layer_pop js_popup">
                <div className="pop_header">
                    <h2>레시피 이미지</h2>
                    <button type="button" className="pop_close" onClick={() => viewHide('1')}><i
                        className="xi-close-thin"></i>닫기
                    </button>
                </div>
                <div className="pop_content">
                    {
                        imagePath &&
                        <div className="recipe_img">
                            <img src={imgUrl + imagePath} alt="" />
                        </div>
                    }
                </div>
                <div className="pop_footer">
                    <button type="button" className="btn" onClick={() => viewHide('1')}>닫기</button>
                </div>
            </div>
            <div className="popup_bg" id="js_popup_bg"></div>
        </>
    );
}

export default ImageView;
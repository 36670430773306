import { useNavigate } from "react-router-dom";

// 네비게이션 관련 함수들을 커스텀 훅으로 정의
const useNavigation = () => {
    const navigate = useNavigate();

    // 로그인 화면으로 이동
    const handleLoginPage = () => {
        navigate("/");
    };
    // 이전페이지 이동
    const handleGoBack = () => {
        navigate(-1);  // -1은 뒤로 가기를 의미
    };

    // 식재료 목록 화면으로 이동
    const handleList = () => {
        navigate("/list");
    };
    // 식재료 등록 화면으로 이동
    const handleWrite = () => {
        navigate("/write");
    };
    // 식재료 조회 화면 이동
    const handleView = (docId) => {
        navigate(`/view/${docId}`);
    }

    // 레시피 목록 화면으로 이동
    const goRecipesList = () => {
        navigate("/recipesList");
    };
    // 레시피 등록 화면 이동
    const goRecipesWrite = () => {
        navigate("/recipesWrite");
    }
    // 레시피 조회 화면 이동
    const goRecipesView = (docId) => {
        navigate(`/recipesView/${docId}`);
    }
    // 로그아웃
    const handleLogout = () => {
        sessionStorage.removeItem("loggedInUser"); // 로그아웃시 사용 (삭제)
        navigate("/");
    }

    // 모든 함수들을 반환
    return {
        handleLoginPage,
        handleList,
        handleWrite,
        handleView,
        goRecipesList,
        goRecipesWrite,
        goRecipesView,
        handleGoBack,
        handleLogout,
    };
};

export default useNavigation;
import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import logo from '../../images/img_logo.png';
import Swal from 'sweetalert2';
import useNavigation from "../../utils/useNavigation";

/* firebase */
import { db } from "../../utils/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

function Login() {

    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    const [loginId, setLoginId] = useState(sessionStorage.getItem("loggedInUser"));
    const { handleList } = useNavigation();

    // 로그인 체크
    useEffect(() => {
        if(loginId) {
            handleList();
        }
    }, [loginId]);

    // 로그인 확인
    const handleLogin = async (e) => {
        e.preventDefault(); // 기본 폼 제출 동작 방지

        const usersCollectionRef = collection(db, "user");

        const q = query(
            usersCollectionRef,
            where("id", "==", id),
            where("pw", "==", password)
        );

        const querySnapshot = await getDocs(q);

        // 로그인 정보 일치
        if (!querySnapshot.empty) {
            // sessionStorage는 브라우저 탭이 열려 있는 동안만 데이터를 유지
            sessionStorage.setItem("loggedInUser", id); // 저장
            // sessionStorage.removeItem("loggedInUser"); // 로그아웃시 사용 (삭제)

            handleList();
        // 로그인 정보 불일치
        } else {
            // alert("아이디 또는 비밀번호가 잘못되었습니다.");
            await Swal.fire({
                title: '로그인실패',
                text: '아이디 또는 비밀번호가 잘못되었습니다.',
                icon: 'error',
                confirmButtonText: '확인'
            });
        }
    };

    // enter key 이벤트
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleLogin(e);
        }
    };

    return (
        <main id="container">
            <div className="login_wrap">
                <div className="login_box">
                    <h1 className="logo"><img src={logo} alt="유성이네 로고"/></h1>
                    <form onKeyDown={handleKeyDown}>
                        <fieldset>
                            <div className="input_box">
                                <ul>
                                    <li>
                                        <label htmlFor="">아이디</label>
                                        <input type="text" placeholder="아이디를 입력해주세요." title="아이디 입력"
                                               value={id}
                                               onChange={(e) => setId(e.target.value)}
                                        />
                                        <button className="btn_del" tabIndex="-1">내용삭제</button>
                                    </li>
                                    <li>
                                        <label htmlFor="">비밀번호</label>
                                        <input type="password" placeholder="비밀번호를 입력해주세요." title="비밀번호 입력"
                                               value={password}
                                               onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <button className="btn_del" tabIndex="-1">내용삭제</button>
                                    </li>
                                </ul>
                            </div>
                            <span className="chk">
                          <span className="cbx"><input type="checkbox" name="cbx01" id="cbx01_1"/><label
                              htmlFor="cbx01_1">로그인 유지</label></span>
                        </span>
                            <div className="btn_area">
                                <button type="button" className="btn blue btn_login" onClick={handleLogin}>로그인</button>
                            </div>
                            {/*<ul className="links">*/}
                            {/*    <li><a href="" className="btn_line blue">회원가입</a></li>*/}
                            {/*</ul>*/}
                        </fieldset>
                    </form>
                </div>
            </div>
        </main>
    );
}

export default Login;
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { collection, addDoc, getDocs, setDoc, doc, query, orderBy, limit } from "firebase/firestore";
import {db} from "../../utils/firebase";
import Swal from "sweetalert2";
import useNavigation from "../../utils/useNavigation";

function Write() {

    const { handleGoBack, handleList, goRecipesList } = useNavigation();

    const [category, setCategory] = useState("");
    const [detailCategory, setDetailCategory] = useState("");
    const [id, setId] = useState(sessionStorage.getItem("loggedInUser"));
    const [ingredientName, setIngredientName] = useState("");
    const [quantity, setQuantity] = useState(1);

    const today = new Date().toISOString().split('T')[0];

    const [expiryDate, setExpiryDate] = useState(today);
    const navigate = useNavigate();

    // 등록하기
    const handleWrite = async () => {
        try {
            if (category.trim() === "" ||
                detailCategory.trim() === "" ||
                ingredientName === "" ||
                quantity < 0 ||
                expiryDate.trim() === "")
            {
                await Swal.fire({
                    title: '재료 등록 실패',
                    text: '카테고리, 세부 카테고리, 재료명, 수량, 유통기한을 입력해주세요.',
                    icon: 'error',
                    confirmButtonText: '확인'
                });
                return false;
            }

            const collectionRef = collection(db, "ingredients");

            const q = query(collectionRef, orderBy("reg_date", "desc"), limit(1));
            const querySnapshot = await getDocs(q);

            let newId = "ingre0001";
            if (!querySnapshot.empty) {
                const lastDoc = querySnapshot.docs[0];
                const lastId = lastDoc.id; // 예: "ingre0001"

                const lastNumber = parseInt(lastId.replace("ingre", ""), 10);
                const nextNumber = lastNumber + 1;

                newId = `ingre${String(nextNumber).padStart(4, "0")}`;
            }

            const newDocument = {
                category: category,
                detailCategory: detailCategory,
                expiryDate: expiryDate,
                ingredientName: ingredientName,
                quantity: quantity,
                id: id,
                regDate: new Date(),
                updDate: '',
            };
            await setDoc(doc(collectionRef, newId), newDocument);
            await Swal.fire({
                title: "식재료 등록",
                text: "식재료가 등록되었습니다.",
                icon: "success",
                confirmButtonText: '확인'
            });
            navigate("/list");
        } catch (e) {
            console.log(e.message);
            await Swal.fire({
                title: '식재료 등록 실패',
                text: '식재료 등록이 실패하였습니다.',
                icon: 'error',
                confirmButtonText: '확인'
            });
        }
    }

    // 수량 증가
    const increaseQuantity = () => {
        setQuantity(quantity + 1); // 수량 1 증가
    };
    // 수량 감소 함수
    const decreaseQuantity = () => {
        setQuantity(quantity - 1); // 수량 1 감소
    };

    const [categoryItem, setCategoryItem] = useState([]); // 카테고리 데이터
    const [detailCategoryItem, setDetailCategoryItem] = useState([]); // 세부 카테고리 데이터

    useEffect(() => {
        // 데이터 불러오기
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "codes"));
                const cateData = [];
                const detailData = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    // 카테고리
                    if(data.step == 1) {
                        cateData.push({ id:data.code, ...data });
                    // 세부 카테고리
                    } else if(data.step == 2) {
                        detailData.push({ id:data.code,  ...data });
                    }
                });
                setCategoryItem(cateData);
                setDetailCategoryItem(detailData);
            } catch (error) {
                console.error("데이터를 불러오는 중 오류 발생:", error);
            }
        };
        fetchData();
    }, []);

    return(
        <>
            <header id="header">
                <button className="btn_history_back" onClick={handleGoBack}>이전</button>
                <h2>식재료 등록</h2>
            </header>
            <main id="container">
                <div className="content">
                    <div className="board_write">
                        <dl>
                            <dt>카테고리</dt>
                            <dd>
                                <span className="chk">
                                    {
                                        categoryItem.map((item, index) => (
                                            <span key={item.id} className="radio">
                                                <input type="radio" name="radio01" id={`radio01_${index + 1}`} value={item.code}
                                                       onClick={(e) => setCategory(e.target.value)}
                                                />
                                                <label htmlFor={`radio01_${index + 1}`}>{item.code_name}</label>
                                            </span>
                                        ))
                                    }
                                </span>
                            </dd>
                        </dl>
                        <dl>
                            <dt>세부 카테고리</dt>
                            <dd>
                                <span className="chk">
                                    {
                                        detailCategoryItem.map((item, index) => (
                                            <span key={item.id} className="radio">
                                                <input type="radio" name="radio02" id={`radio02_${index + 1}`} value={item.code}
                                                       onClick={(e) => setDetailCategory(e.target.value)}
                                                />
                                                <label htmlFor={`radio02_${index + 1}`}>{item.code_name}</label>
                                            </span>
                                        ))
                                    }
                              </span>
                            </dd>
                        </dl>
                        <div className="input_wrap">
                            <button className="btn sml icl blue"><i className="xi-plus"></i> 추가</button>
                            <div className="col2">
                                <dl>
                                    <dt>재료명</dt>
                                    <dd>
                                        <input type="text"
                                           value={ingredientName}
                                           onChange={(e) => setIngredientName(e.target.value)}
                                        />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>수량</dt>
                                    <dd>
                                        <div className="count_area">
                                            <button className="btn_minus" onClick={decreaseQuantity}>-</button>
                                            <input type="number" value={quantity} className="counter" />
                                                <button className="btn_plus" onClick={increaseQuantity}>+</button>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                            <dl>
                                <dt>유통기한</dt>
                                <dd>
                                    <input type="date" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="btn_bottom_area">
                        <button className="btn" onClick={handleList}>목록</button>
                        <button className="btn blue" onClick={handleWrite}>등록</button>
                    </div>
                </div>
            </main>
            <div id="fixed_menu">
                <ul>
                    <li className="menu1 on"><a href="#" onClick={handleList}>식재료 지도</a></li>
                    <li className="menu2"><a href="#">식단표</a></li>
                    <li className="menu3"><a href="#" onClick={goRecipesList}>레시피</a></li>
                    <li className="menu4"><a href="#">장보기</a></li>
                </ul>
            </div>
        </>
    );
}

export default Write;
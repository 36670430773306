import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { collection, doc, addDoc, setDoc, getDocs, query, limit, orderBy } from "firebase/firestore";
import { db } from "../../utils/firebase";
import Swal from "sweetalert2";
import useNavigation from "../../utils/useNavigation";
import imgUtil from "../../utils/imgUtil";

function Write() {

    const { handleGoBack, goRecipesList, handleList } = useNavigation();

    const { imgUrl } = imgUtil();

    const [thumbnailKey, setThumbnailKey] = useState(0);
    const [imageKey, setImageKey] = useState(0);

    // 데이터
    const [category, setCategory] = useState("");
    const [id, setId] = useState(sessionStorage.getItem("loggedInUser"));

    const [ingredients, setIngredients] = useState("");
    const [link, setLink] = useState("");
    const [mainIngredient, setMainIngredient] = useState("");
    const [recipe, setRecipe] = useState("");
    const [recipeTitle, setRecipeTitle] = useState("");

    const [thumbnail, setThumbnail] = useState(null);                      // 썸네일 파일 정보
    const [thumbnailName, setThumbnailName] = useState("");

    const [image, setImage] = useState(null);                              // 이미지 파일 정보
    const [imageName, setImageName] = useState("");

    // 등록하기
    const handleWrite = async () => {
        try {
            if (category.trim() === "" ||
                recipeTitle.trim() === "" ||
                thumbnail === null ||
                mainIngredient.trim() === "" ||
                ingredients.trim() === "" ||
                recipe.trim() === ""
            ) {
                await Swal.fire({
                    title: '레시피 등록 실패',
                    text: '카테고리, 요리제목, 썸네일, 메인재료, 재료, 레시피를 입력해주세요.',
                    icon: 'error',
                    confirmButtonText: '확인'
                });
                return false;
            }

            /*
                첨부파일 업로드
             */
            const formData = new FormData();
            formData.append("file", thumbnail);
            formData.append("file2", image);

            let has = false;
            let thumbnailPath = "";
            let imagePath = "";
            const response = await fetch(`${imgUrl}/upload`, {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                const data = await response.json();
                thumbnailPath = data.filePaths.file || '';
                imagePath = data.filePaths.file2 || '';
                has = true;
            } else {
                console.log("업로드 실패");
            }

            if (has) {
                /*
                    데이터 업로드
                */
                const collectionRef = collection(db, "recipes");
                const q = query(collectionRef, orderBy("reg_date", "desc"), limit(1));
                const querySnapshot = await getDocs(q);

                let newId = "recipe0001";
                if (!querySnapshot.empty) {
                    const lastDoc = querySnapshot.docs[0];
                    const lastId = lastDoc.id; // 예: "recipe0001"

                    const lastNumber = parseInt(lastId.replace("recipe", ""), 10);
                    const nextNumber = lastNumber + 1;

                    newId = `recipe${String(nextNumber).padStart(4, "0")}`;
                }

                const newDocument = {
                    category: category,
                    imageName: imageName,
                    imagePath: imagePath,
                    ingredients: ingredients,
                    link: link,
                    mainIngredient: mainIngredient,
                    recipe: recipe,
                    recipeTitle: recipeTitle,
                    id: id,
                    regDate: new Date(),
                    thumbnailName: thumbnailName,
                    thumbnailPath: thumbnailPath
                };
                await setDoc(doc(collectionRef, newId), newDocument);
                Swal.fire({
                    title: "레시피 등록",
                    text: "레시피가 등록되었습니다.",
                    icon: "success",
                    confirmButtonText: '확인'
                });
                goRecipesList();
            } else {
                Swal.fire({
                    title: "레시피 등록",
                    text: "첨부파일 업로드가 실패하였습니다.",
                    icon: "error",
                    confirmButtonText: '확인'
                });
            }
        } catch (e) {
            console.log(e.message);
            await Swal.fire({
                title: '레시피 등록',
                text: '레시피 등록이 실패하였습니다.',
                icon: 'error',
                confirmButtonText: '확인'
            });
        }
    }

    // 썸네일 지정
    const handleThumbnailChange = (e) => {
        const selectedFile = e.target.files[0];
        setThumbnail(selectedFile);
        setThumbnailName(selectedFile.name);
    }

    // 썸네일 삭제
    const handleThumbnailRemove = () => {
        setThumbnail(null);
        setThumbnailName('');
        // 똑같은 첨부파일 업로드를 위한 key로 리렌더링
        setThumbnailKey((prevKey) => prevKey + 1);
    }

    // 이미지 지정
    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setImage(selectedFile);
        setImageName(selectedFile.name);
    }

    // 이미지 삭제
    const handleImageRemove = () => {
        setImage(null);
        setImageName('');
        // 똑같은 첨부파일 업로드를 위한 key로 리렌더링
        setImageKey((prevKey) => prevKey + 1);
    }

    const [categoryItem, setCategoryItem] = useState([]); // 카테고리 데이터

    useEffect(() => {
        // 데이터 불러오기
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "codes"));
                const cateData = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    // 카테고리
                    if(data.step == 3) {
                        cateData.push({id: data.code, ...data});
                    }
                });
                setCategoryItem(cateData);
            } catch (error) {
                console.error("데이터를 불러오는 중 오류 발생:", error);
            }
        };
        fetchData();
    }, []);

    return(
        <>
            <header id="header">
                <button className="btn_history_back" onClick={handleGoBack}>이전</button>
                <h2>레시피 등록</h2>
            </header>
            <main id="container">
                <div className="content">
                    <div className="board_write">
                        <dl>
                            <dt>카테고리</dt>
                            <dd>
                                <span className="chk">
                                    {
                                        categoryItem.map((item, index) => (
                                            <span key={item.id} className="radio">
                                                <input type="radio" name="radio01" id={`radio01_${index + 1}`}
                                                       value={item.code}
                                                       onClick={(e) => setCategory(e.target.value)}
                                                />
                                                <label htmlFor={`radio01_${index + 1}`}>{item.code_name}</label>
                                            </span>
                                        ))
                                    }
                                </span>
                            </dd>
                        </dl>
                        <dl>
                            <dt>요리제목</dt>
                            <dd>
                                <input type="text"
                                   value={recipeTitle}
                                   onChange={(e) => setRecipeTitle(e.target.value)}
                                />
                            </dd>
                        </dl>
                        <dl>
                            <dt>썸네일</dt>
                            <dd>
                                <div className="filebox">
                                    <input key={thumbnailKey} type="file" id="file" className="file" onChange={handleThumbnailChange} accept="image/*" multiple />
                                    <label htmlFor="file" className="btn blue icl" tabIndex="0"><i className="xi-search"></i>파일찾기</label>
                                    {
                                        thumbnailName &&
                                        <ul>
                                            <li><p className="upload_name">{thumbnailName}</p>
                                                <button className="btn_del" onClick={handleThumbnailRemove}>파일삭제</button>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </dd>
                        </dl>
                        <dl>
                            <dt>링크</dt>
                            <dd>
                                <input type="text"
                                       value={link}
                                       onChange={(e) => setLink(e.target.value)}
                                />
                            </dd>
                        </dl>
                        <dl>
                            <dt>메인재료</dt>
                            <dd>
                                <input type="text" placeholder=",로 구분"
                                    value={mainIngredient}
                                    onChange={(e) => setMainIngredient(e.target.value)}
                                />
                            </dd>
                        </dl>
                        <dl>
                            <dt>재료</dt>
                            <dd>
                                <textarea name="" id="" style={{ height:'15rem' }}
                                    value={ingredients}
                                    onChange={(e) => setIngredients(e.target.value)}
                                />
                            </dd>
                        </dl>
                        <dl>
                            <dt>레시피</dt>
                            <dd>
                                <textarea name="" id="" style={{ height:'35rem' }}
                                    value={recipe}
                                    onChange={(e) => setRecipe(e.target.value)}
                                />
                            </dd>
                        </dl>
                        <dl>
                            <dt>레시피 (이미지)</dt>
                            <dd>
                                <div className="filebox">
                                    <input key={imageKey} type="file" id="file2" className="file" onChange={handleImageChange} accept="image/*" multiple/>
                                    <label htmlFor="file2" className="btn blue icl" tabIndex="0"><i
                                        className="xi-search"></i>파일찾기</label>
                                    {
                                        imageName &&
                                        <ul>
                                            <li><p className="upload_name">{imageName}</p>
                                                <button className="btn_del" onClick={handleImageRemove}>파일삭제</button>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="btn_bottom_area">
                    <button className="btn" onClick={goRecipesList}>목록</button>
                    <button className="btn blue" onClick={handleWrite}>등록</button>
                </div>
            </main>
            <div id="fixed_menu">
                <ul>
                    <li className="menu1"><a href="#" onClick={handleList}>식재료 지도</a></li>
                    <li className="menu2"><a href="#">식단표</a></li>
                    <li className="menu3 on"><a href="#" onClick={goRecipesList}>레시피</a></li>
                    <li className="menu4"><a href="#">장보기</a></li>
                </ul>
            </div>
        </>
    );
}

export default Write;
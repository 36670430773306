import "./css/jquery-ui-1.12.1.custom.css";
import "./css/mobile.css";
import "./css/common.css";

import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from './component/contents/Login';
import List from './component/ingredients/List';
import Write from './component/ingredients/Write';
import View from './component/ingredients/View';
import RecipesList from './component/recipes/List';
import RecipesWrite from './component/recipes/Write';
import RecipesView from './component/recipes/View';

function App() {
    return (
        <div id="wrapper">
            {/* contents */}
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Login/>}/>
                    <Route path='/list' element={<List/>}/>
                    <Route path='/write' element={<Write />} />
                    <Route path='/view/:docId' element={<View/>}/>
                    <Route path='/recipesList' element={<RecipesList/>}/>
                    <Route path='/recipesWrite' element={<RecipesWrite/>}/>
                    <Route path='/recipesView/:docId' element={<RecipesView/>}/>
                </Routes>
            </BrowserRouter>
            {/* contents */}
            <footer id="footer"></footer>
        </div>
    );
}

export default App;

const common = () => {

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하므로 +1을 해줍니다
        const day = String(date.getDate()).padStart(2, '0');  // 일자는 2자리로 맞추기 위해 padStart 사용

        return `${year}-${month}-${day}`;
    };

    return {
        formatDate
    }
}

export default common;
import { useParams } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {addDoc, collection, doc, getDoc, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
import {db} from "../../utils/firebase";
import Swal from "sweetalert2";
import useNavigation from "../../utils/useNavigation";
import imgUtil from "../../utils/imgUtil";
import ImageView from "./ImageView";
import $ from 'jquery';

function View() {

    const { handleGoBack, goRecipesList } = useNavigation();

    const { imgUrl } = imgUtil();

    // 데이터
    const [category, setCategory] = useState("");
    const [id, setId] = useState(sessionStorage.getItem("loggedInUser"));

    const [ingredients, setIngredients] = useState("");
    const [link, setLink] = useState("");
    const [mainIngredient, setMainIngredient] = useState("");
    const [recipe, setRecipe] = useState("");
    const [recipeTitle, setRecipeTitle] = useState("");

    const [thumbnail, setThumbnail] = useState(null);                      // 썸네일 파일 상태
    const [thumbnailName, setThumbnailName] = useState("");
    const [thumbnailPath, setThumbnailPath] = useState("");

    const [image, setImage] = useState(null);                              // 이미지 파일 상태
    const [imageName, setImageName] = useState("");
    const [imagePath, setImagePath] = useState("");

    const { docId } = useParams();  // URL에서 docId 추출

    // 저장하기
    const handleUpdate = async () => {
        try {
            if (category.trim() === "" ||
                ingredients.trim() === "" ||
                mainIngredient.trim() === "" ||
                recipe.trim() === "" ||
                recipeTitle.trim() === "")
            {
                await Swal.fire({
                    title: '레시피 저장 실패',
                    text: '레시피 내용을 모두 입력해주세요.',
                    icon: 'error',
                    confirmButtonText: '확인'
                });
                return false;
            }

            let has = false;
            let thumbnailPath = "";
            let imagePath = "";
            if(thumbnail) {
                /*
                    첨부파일이 다르면
                 */
                const formData = new FormData();
                formData.append("file", thumbnail);

                const response = await fetch(`${imgUrl}/upload`, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    const data = await response.json();
                    thumbnailPath = data.filePath;
                    imagePath = data.filePath2;
                    has = true;
                } else {
                    console.log("업로드 실패");
                }
            }

            const docRef = doc(db, "recipes", docId);
            let updatedDocument  = {
                category: category,
                image: imageName,
                ingredients: ingredients,
                link: link,
                mainIngredient: mainIngredient,
                recipe: recipe,
                recipeTitle: recipeTitle,
                id: id,
                updDate: new Date(),
                thumbnailName: thumbnailName
            };

            if(has) {
                updatedDocument['thumbnailPath'] = thumbnailPath;
                updatedDocument['imagePath'] = imagePath;
            }

            try {
                // 문서 업데이트
                await updateDoc(docRef, updatedDocument);
            } catch (error) {
                console.error("레시피 업데이트 실패: ", error);
            }

            await Swal.fire({
                title: "레시피 저장",
                text: "레시피가 저장되었습니다.",
                icon: "success",
                confirmButtonText: '확인'
            });
            goRecipesList();
        } catch (e) {
            await Swal.fire({
                title: '레시피 저장',
                text: '레시피 저장이 실패하였습니다.',
                icon: 'error',
                confirmButtonText: '확인'
            });
        }
    }

    // 썸네일 지정
    const handleThumbnailChange = (e) => {
        const selectedFile = e.target.files[0];
        setThumbnail(selectedFile);
        setThumbnailName(selectedFile.name);
    }

    // 이미지 지정
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageName(file.name);
            setImage(file);
        }
    }

    const [categoryItem, setCategoryItem] = useState([]); // 카테고리 데이터

    useEffect(() => {
        // 데이터 불러오기
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "codes"));
                const cateData = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    // 카테고리
                    if(data.step == 3) {
                        cateData.push({id: data.code, ...data});
                    }
                });
                setCategoryItem(cateData);

                // 레시피 가져오기
                const docRef = doc(db, "recipes", docId);
                const docSnap = await getDoc(docRef);  // 문서 가져오기

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setCategory(data.category);
                    setLink(data.link);
                    setImageName(data.imageName);
                    setImagePath(data.imagePath);
                    setRecipe(data.recipe);
                    setIngredients(data.ingredients);
                    setMainIngredient(data.mainIngredient);
                    setRecipeTitle(data.recipeTitle);
                    setThumbnailName(data.thumbnailName);
                    setThumbnailPath(data.thumbnailPath);
                } else {
                    console.log("No such document!");
                }

            } catch (error) {
                console.error("데이터를 불러오는 중 오류 발생:", error);
            }
        };
        fetchData();
    }, []);

    // 삭제하기
    const handleDelete = async (docId) => {
        Swal.fire({
            title: "레시피 삭제",
            text: "레시피를 삭제하시겠습니까?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "삭제",
            cancelButtonText: "취소"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    const docRef = doc(db, "recipes", docId);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        if (data['id'] === id) {
                            await deleteDoc(doc(db, "recipes", docId));
                            Swal.fire({
                                title: "레시피 삭제",
                                text: "레시피가 삭제되었습니다.",
                                icon: "success"
                            });
                            // 목록 재호출
                            goRecipesList();
                        } else {
                            Swal.fire({
                                title: "레시피 삭제",
                                text: "본인 레시피만 삭제 가능합니다.",
                                icon: "warning"
                            });
                        }
                    } else {
                        Swal.fire({
                            title: "레시피 삭제",
                            text: "레시피가 존재하지 않습니다.",
                            icon: "warning"
                        });
                    }
                } catch (error) {
                    console.error("문서 삭제 중 오류 발생:", error);
                }

            }
        });

    }

    // 레이어 팝업 open
    const viewShow = (popName) => {
        let top = ($(window).height() - $("#display_view" + popName).outerHeight()) / 2;
        $("#js_popup_bg").show();
        $("#display_view" + popName).addClass("on").css({ 'visibility': 'visible', 'position': 'fixed', 'z-index': 5500 });
        return false;
    }

    return(
        <>
            <header id="header">
                <button className="btn_history_back" onClick={handleGoBack}>이전</button>
                <h2>레시피 상세</h2>
                <button className="btn_del" onClick={() => handleDelete(docId)}>삭제</button>
            </header>
            <main id="container">
                <div className="content">
                    <div className="board_view">
                        <div className="recipe_top">
                            <div className="img_area">
                                <img src={imgUrl + thumbnailPath} alt=""/>
                            </div>
                            <b>{recipeTitle}
                                <button className="btn_favorite">즐겨찿기 <i className="xi-star"></i></button>
                            </b>
                        </div>
                        <dl>
                            <dt>링크</dt>
                            <dd><a href={link} target="_blank" className="link">{link}</a></dd>
                        </dl>
                        <dl>
                            <dt>메인재료</dt>
                            <dd>{mainIngredient}</dd>
                        </dl>
                        <dl className="w100p">
                            <dt>재료</dt>
                            <dd>
                                <pre>
                                    {ingredients}
                                </pre>
                            </dd>
                        </dl>
                        <dl className="w100p">
                            <dt>레시피
                                <button className="btn sml icl btn_recipe_photo" onClick={() => viewShow("1")}>
                                <i className="xi-search"></i>레시피 이미지</button>
                            </dt>
                            <dd>
                                <pre>
                                    {recipe}
                                </pre>
                            </dd>
                        </dl>
                    </div>
                    {/*<div className="comment_area">*/}
                    {/*    <p>댓글</p>*/}
                    {/*    <div className="comment_write">*/}
                    {/*        <textarea name="" id=""></textarea>*/}
                    {/*        <button className="btn blue">등록</button>*/}
                    {/*    </div>*/}
                    {/*    <div className="comment_list">*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                <pre>존맛레시피임! 만들어봄</pre>*/}
                    {/*                <p>2024.11.11</p>*/}
                    {/*                <button className="btn_del btn sml red">삭제</button>*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="btn_bottom_area">
                        <button className="btn" onClick={goRecipesList}>목록</button>
                        <button className="btn dark" onClick={() => alert('준비중')}>수정</button>
                    </div>
                </div>
            </main>
            <ImageView imagePath={imagePath} />
        </>
    );
}

export default View;
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import { collection, getDocs, deleteDoc, doc, query, where } from "firebase/firestore";
import {db} from "../../utils/firebase";
import Swal from "sweetalert2";
import useNavigation from "../../utils/useNavigation";
import imgUtil from "../../utils/imgUtil";

function List() {

    /*
        검색 조건 값
     */
    const seCategory = sessionStorage.getItem("seCategory") || "code0010";
    const seSearchKeyWord = sessionStorage.getItem("seSearchKeyWord") || "";

    const { handleGoBack, handleList, goRecipesList, goRecipesWrite, goRecipesView, handleLogout } = useNavigation();
    const { imgUrl } = imgUtil();

    const [categoryItem, setCategoryItem] = useState([""]); // 카테고리 데이터
    const [recipes, setRecipes] = useState([]); // 레시피
    const [recipesCnt, setRecipesCnt] = useState(0); // 레시피 개수
    const [id, setId] = useState(sessionStorage.getItem("loggedInUser"));

    const [category, setCategory] = useState(seCategory);   // 최초 건강레시피 설정
    const [searchKeyWord, setSearchKeyWord] = useState(seSearchKeyWord);   // 검색어

    useEffect(() => {
        // 데이터 불러오기
        const fetchData = async () => {
            try {
                // 카테고리
                const querySnapshot = await getDocs(collection(db, "codes"));
                const cateData = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    // 레시피 카테고리
                    if(data.step == 3) {
                        cateData.push({ id:data.code, ...data });
                    }
                });
                setCategoryItem(cateData);
                if (!category || category.trim() === "") {
                    setCategory(cateData[0].code);
                }

                getRecipes();
            } catch (error) {
                console.error("데이터를 불러오는 중 오류 발생:", error);
            }
        };
        fetchData();
    }, []);

    const getRecipes = async () => {
        // 레시피
        let q2 = query(
            collection(db, "recipes"),
            // where("id", "==", id),
            where("category", "==", category)
        );

        // searchKeyWord가 비어 있지 않을 때만 조건 추가
        if (searchKeyWord && searchKeyWord.trim() !== "") {
            q2 = query(q2, where("recipeTitle", "==", searchKeyWord));
        }

        const querySnapshot2 = await getDocs(q2);

        const recipesData = [];
        querySnapshot2.forEach((doc) => {
            const data = doc.data();
            recipesData.push({ docId: doc.id, ...data });
        });
        setRecipes(recipesData);
        setRecipesCnt(recipesData.length);
    }

    // 삭제하기
    const handleDelete = async (docId) => {
        Swal.fire({
            title: "식재료 삭제",
            text: "식재료를 삭제하시겠습니까?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "삭제",
            cancelButtonText: "취소"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    // 문서 아이디와 등록자 아이디
                    let q = query(
                        collection(db, "recipes"),
                        where("docId", "==", docId),
                        where("id", "==", id),
                    );

                    const querySnapshot = await getDocs(q);

                    // await deleteDoc(doc(db, "ingredients", docId));

                    // Swal.fire({
                    //     title: "식재료 삭제",
                    //     text: "식재료가 삭제되었습니다. " + docId,
                    //     icon: "success"
                    // });
                    // // 목록 재호출
                    // getRecipes();
                } catch (error) {
                    console.error("문서 삭제 중 오류 발생:", error);
                }

            }
        });

    }

    useEffect(() => {
        getRecipes();
    }, [category]);

    useEffect(() => {
        getRecipes();
    }, [searchKeyWord]);

    // 검색 버튼 클릭
    const handleSearch = () => {
        getRecipes();
    }

    // 상세 조회시 검색 데이터 저장
    const handleRecipesView = (docId) => {
        sessionStorage.setItem("seCategory", category);
        sessionStorage.setItem("seSearchKeyWord", searchKeyWord);
        goRecipesView(docId);
    }

    return(
        <>
            <header id="header">
                <button className="btn_history_back" onClick={handleGoBack}>이전</button>
                <h2>레시피</h2>
                <button className="btn_logout" onClick={handleLogout}>로그아웃</button>
            </header>

            <main id="container">
                <div className="content">
                    <div className="tab basic">
                        <ul className="tab_menu" role="tablist">
                            {
                                categoryItem.map((item, index) => (
                                    <li key={index}>
                                        <a href="#" onClick={() => setCategory(item.code
                                        )} role="tab" aria-selected="true"
                                                           aria-controls={`tab1_0${index + 1}`}
                                            className={item.code === category ? "on" : "" }
                                        >
                                            {item.code_name}
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>
                        <div id="tab1_01" className="tab_cont" role="tabpanel" aria-labelledby="tab1_01">
                            <div className="top_sch">
                                <input type="text" placeholder="검색어 입력" value={searchKeyWord} onChange={(e) => setSearchKeyWord(e.target.value)} />
                                <button className="btn_sch" onClick={handleSearch}>검색<i className="xi-search"></i></button>
                            </div>
                            <div className="recipe_list">
                                <div className="list_top">
                                    <p className="count"><i className="xi-paper-o"></i>전체 <b>{recipesCnt}</b>건</p>
                                    <select name="" id="">
                                        <option value="">전체</option>
                                        <option value="">즐겨찾기</option>
                                    </select>
                                </div>
                                <ul>
                                    {
                                        recipes.map((item, index) => (
                                            <li key={item.docId}>
                                                <a href="#" onClick={() => handleRecipesView(item.docId)}>
                                                    <div className="img_area">
                                                        <img src={imgUrl + item.thumbnailPath} alt="" />
                                                    </div>
                                                    <b>{item.recipeTitle}</b>
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <button className="btn_write_float" onClick={goRecipesWrite}>등록</button>
                </div>
            </main>

            <div id="fixed_menu">
                <ul>
                    <li className="menu1"><a href="#" onClick={handleList}>식재료 지도</a></li>
                    <li className="menu2"><a href="#">식단표</a></li>
                    <li className="menu3 on"><a href="#" onClick={goRecipesList}>레시피</a></li>
                    <li className="menu4"><a href="#">장보기</a></li>
                </ul>
            </div>
        </>
    );
}

export default List;
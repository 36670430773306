import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {collection, deleteDoc, doc, getDoc, getDocs, query, where} from "firebase/firestore";
import {db} from "../../utils/firebase";
import Swal from "sweetalert2";
import useNavigation from "../../utils/useNavigation";
import imgUtil from "../../utils/imgUtil";
import common from "../../utils/common";


function View() {

    const { handleList, handleWrite, handleGoBack } = useNavigation();
    const { formatDate } = common();
    const { imgUrl } = imgUtil();

    // 데이터
    const [id, setId] = useState(sessionStorage.getItem("loggedInUser"));

    const [ingredientName, setIngredientName] = useState("");
    const [quantity, setQuantity] = useState(0);
    const [expiryDate, setExpiryDate] = useState("");
    const [regDate, setRegDate] = useState("");

    const [recipeList, setRecipList] = useState([]);

    const { docId } = useParams();  // URL에서 docId 추출

    useEffect(() => {
        // 데이터 불러오기
        const fetchData = async () => {
            try {
                // 식재료 가져오기
                const docRef = doc(db, "ingredients", docId);
                const docSnap = await getDoc(docRef);  // 문서 가져오기

                let ingredientName = "";
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setIngredientName(data.ingredientName);
                    setQuantity(data.quantity);
                    setExpiryDate(data.expiryDate);
                    const regDateTime = data.regDate
                    setRegDate(formatDate(new Date((regDateTime.seconds * 1000) + regDateTime.nanoseconds / 1000000)));
                    // 재료명
                    ingredientName = data.ingredientName;
                } else {
                    console.log("No such document!");
                }

                // 재료명으로 레시피 메인재료 가져오기
                const q = query(collection(db, "recipes"));
                const querySnapshot = await getDocs(q);
                const filteredRecipes  = [];
                // 모든 문서를 반복하며 'mainIngredient'에 ingredientName이 포함되는지 확인
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.mainIngredient && data.mainIngredient.includes(ingredientName)) {
                        // 포함되는 경우, 필터링된 배열에 추가
                        filteredRecipes.push(data);
                    }
                });

                const recipeItems = [];
                for (let i = 0; i < filteredRecipes.length; i++) {
                    const recipe = filteredRecipes[i];
                    recipeItems.push(
                        <li key={i}>
                            <a href="#">
                                <div className="img_area">
                                    <img src={imgUrl + recipe.thumbnailPath} alt={recipe.recipeTitle}/>
                                </div>
                                <b>요리제목 : {recipe.recipeTitle}</b>
                                <b>메인재료 : {recipe.mainIngredient}</b>
                            </a>
                        </li>
                    );
                }
                setRecipList(recipeItems);

            } catch (error) {
                console.error("데이터를 불러오는 중 오류 발생:", error);
            }
        };
        fetchData();
    }, []);

    // 삭제하기
    const handleDelete = async (docId) => {
        Swal.fire({
            title: "식재료 삭제",
            text: "식재료를 삭제하시겠습니까?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "삭제",
            cancelButtonText: "취소"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    const docRef = doc(db, "ingredients", docId);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        if (data['id'] === id) {
                            await deleteDoc(doc(db, "ingredients", docId));
                            Swal.fire({
                                title: "식재료 삭제",
                                text: "식재료가 삭제되었습니다.",
                                icon: "success"
                            });
                            // 목록 재호출
                            handleList();
                        } else {
                            Swal.fire({
                                title: "식재료 삭제",
                                text: "본인 식재료만 삭제 가능합니다.",
                                icon: "warning"
                            });
                        }
                    } else {
                        Swal.fire({
                            title: "식재료 삭제",
                            text: "식재료가 존재하지 않습니다.",
                            icon: "warning"
                        });
                    }
                } catch (error) {
                    console.error("문서 삭제 중 오류 발생:", error);
                }

            }
        });

    }

    return(
        <>
            <header id="header">
                <button className="btn_history_back" onClick={handleGoBack}>이전</button>
                <h2>식재료 상세</h2>
                <button className="btn_del" onClick={() => handleDelete(docId)}>삭제</button>
            </header>
            <main id="container">
                <div className="content">
                    <div className="board_view">
                        <b className="ingredients_tit">{ingredientName} <span className="count">{quantity}</span></b>
                        <dl>
                            <dt>등록일</dt>
                            <dd>{regDate}</dd>
                        </dl>
                        <dl>
                            <dt>유통기한</dt>
                            <dd>{expiryDate}</dd>
                        </dl>
                        <dl className="w100p">
                            <dt>관련 레시피</dt>
                            <dd>
                                <div className="recipe_list">
                                    <ul>
                                        {
                                            recipeList.length > 0 && recipeList
                                        }
                                    </ul>
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="btn_bottom_area">
                        <button className="btn" onClick={handleList}>목록</button>
                        <button className="btn dark" onClick={() => alert('준비중')}>수정</button>
                    </div>
                </div>
            </main>
        </>
    );
}

export default View;
/*
 * 이미지 호출을 위한 서버 URL
 */
const imgUtil = () => {
    const imgUrl = process.env.REACT_APP_IMG_URL;

    return {
        imgUrl
    };
}

export default imgUtil;
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {collection, getDocs, deleteDoc, doc, query, where, getDoc} from "firebase/firestore";
import {db} from "../../utils/firebase";
import Swal from "sweetalert2";
import useNavigation from "../../utils/useNavigation";

function List() {

    const { handleGoBack, handleWrite, handleList, handleView, goRecipesList, handleLogout } = useNavigation();

    // 최초 카테고리1
    const seCategory = sessionStorage.getItem("ingreCategory") || "code0001";
    const [category, setCategory] = useState(seCategory);   // 최초 건강레시피 설정

    // 최초 카테고리2
    const seCategory2 = sessionStorage.getItem("ingreCategory2") || "";
    const [category2, setCategory2] = useState(seCategory2);   // 최초 건강레시피 설정


    const [categoryItem, setCategoryItem] = useState([]); // 카테고리 데이터
    const [detailCategoryItem, setDetailCategoryItem] = useState([]); // 세부 카테고리 데이터


    const [ingredients, setIngredients] = useState([]); // 식재료
    const [ingredientCnt, setIngredientCnt] = useState(0); // 식재료 개수

    const [id, setId] = useState(sessionStorage.getItem("loggedInUser"));

    useEffect(() => {
        // 데이터 불러오기
        const fetchData = async () => {
            try {
                // 카테고리, 세부카테고리
                const querySnapshot = await getDocs(collection(db, "codes"));
                const cateData = [];
                const detailData = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    // 카테고리
                    if(data.step == 1) {
                        cateData.push({ id:data.code, ...data });
                        // 세부 카테고리
                    } else if(data.step == 2) {
                        detailData.push({ id:data.code,  ...data });
                    }
                });
                setCategoryItem(cateData);
                setDetailCategoryItem(detailData);

                getIngredients();
            } catch (error) {
                console.error("데이터를 불러오는 중 오류 발생:", error);
            }
        };
        fetchData();
    }, []);

    const getIngredients = async () => {
        // 식재료
        // const q2 = query(collection(db, "ingredients"), where("id", "==", id));
        console.log(category);
        const q2 =
            query(
                collection(db, "ingredients"),
                where("category", "==", category),
                ...(category2.trim() !== "" ? [where("detail_category", "==", category2)] : [])
            );

        const querySnapshot2 = await getDocs(q2);

        const ingredientsData = [];
        querySnapshot2.forEach((doc) => {
            const data = doc.data();
            ingredientsData.push({ docId: doc.id, ...data });
        });
        setIngredients(ingredientsData);
        setIngredientCnt(ingredientsData.length);
    }

    // 삭제하기
    const handleDelete = async (docId) => {
        Swal.fire({
            title: "식재료 삭제",
            text: "식재료를 삭제하시겠습니까?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "삭제",
            cancelButtonText: "취소"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    const docRef = doc(db, "ingredients", docId);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        if (data['id'] === id) {
                            await deleteDoc(doc(db, "ingredients", docId));
                            Swal.fire({
                                title: "식재료 삭제",
                                text: "식재료가 삭제되었습니다.",
                                icon: "success"
                            });
                            // 목록 재호출
                            getIngredients();
                        } else {
                            Swal.fire({
                                title: "식재료 삭제",
                                text: "본인 식재료만 삭제 가능합니다.",
                                icon: "warning"
                            });
                        }
                    } else {
                        Swal.fire({
                            title: "식재료 삭제",
                            text: "식재료가 존재하지 않습니다.",
                            icon: "warning"
                        });
                    }
                } catch (error) {
                    console.error("문서 삭제 중 오류 발생:", error);
                }

            }
        });

    }

    useEffect(() => {
        getIngredients();
    }, [category]);

    useEffect(() => {
        getIngredients();
    }, [category2]);

    const handleIngredientsView = (docId) => {
        sessionStorage.setItem("ingreCategory", category);
        sessionStorage.setItem("ingreCategory2", category2);
        handleView(docId);
    }

    return(
        <>
            <header id="header">
                <button className="btn_history_back" onClick={handleGoBack}>이전</button>
                <h2>식재료 지도</h2>
                <button className="btn_logout" onClick={handleLogout}>로그아웃</button>
            </header>

            <main id="container">
            <div className="content">
                    <div className="tab basic">
                        <ul className="tab_menu" role="tablist">
                            {
                                categoryItem.map((item, index) => (
                                    <li key={index}><a href="#" onClick={() => {
                                        setCategory(item.code);
                                        setCategory2("");
                                    }} role="tab" aria-selected="true" aria-controls={`tab1_0${index + 1}`}
                                       className={item.code === category ? "on" : ""}
                                    >{item.code_name}</a></li>
                                ))
                            }
                        </ul>
                        <div id="tab1_01" className="tab_cont" role="tabpanel" aria-labelledby="tab1_01">
                            <div className="sub_tab_btns">
                                <button onClick={() => setCategory2("")} className={category2 === "" ? "on" : ""}>전체</button>
                                {
                                    detailCategoryItem.map((item, index) => (
                                        <button key={index} onClick={() => setCategory2(item.code)}
                                                className={item.code === category2 ? "on" : ""}
                                        >{item.code_name}</button>
                                    ))
                                }
                            </div>
                            <div className="ingredients_list">
                                <div className="list_top">
                                    <p className="count"><i className="xi-paper-o"></i>전체 <b>{ingredientCnt}</b>건</p>
                                </div>
                                <ul>
                                    {
                                        ingredients.map((item, index) => (
                                            <li key={item.docId}>
                                                <a href="#" onClick={() => handleIngredientsView(item.docId)}>
                                                    <b>{item.ingredientName} <span className="count">{item.quantity}</span></b>
                                                    <p>{item.expiryDate}</p>
                                                </a>
                                                <button className="btn_del" onClick={() => handleDelete(item.docId)}>삭제</button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <button className="btn_write_float" onClick={handleWrite}>등록</button>
                </div>
            </main>

            <div id="fixed_menu">
                <ul>
                    <li className="menu1 on"><a href="#" onClick={handleList}>식재료 지도</a></li>
                    <li className="menu2"><a href="#">식단표</a></li>
                    <li className="menu3"><a href="#" onClick={goRecipesList}>레시피</a></li>
                    <li className="menu4"><a href="#">장보기</a></li>
                </ul>
            </div>
        </>
    );
}

export default List;
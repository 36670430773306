import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCfLfTcwRSGaA0LFuvf1gkySF78uzgBOy4",
    authDomain: "yusg-240525.firebaseapp.com",
    projectId: "yusg-240525",
    storageBucket: "yusg-240525.firebasestorage.app",
    messagingSenderId: "752794482407",
    appId: "1:752794482407:web:399f542218c074d7c1d442"
};

// Firebase 초기화
const app = initializeApp(firebaseConfig);

// Firebase 서비스 가져오기
// export const auth = getAuth(app); // 인증
export const db = getFirestore(app); // Firestore
export const storage = getStorage(app); // storage
export default app;